import React from 'react';
import { useSelector } from 'react-redux';
import EditorialItem from '../../../components/EditorialItem';

import './styles.css';

const MainEditorialContent = () => {
  const articles = useSelector(state => state?.app?.data);
  return (
    <EditorialItem
      content={articles?.records?.[0]?.content?.body}
      title={articles?.records?.[0]?.title}
      classOverride={'article'}
    />
  );
};

export default MainEditorialContent;
